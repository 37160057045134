<template>
  <div class="personalInfoBookingPage">
    <back-title @click="goBack">{{ $t("Customer info") }}</back-title>
    <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
    <template v-else>
      <avatar :text="name" size="large" is-vertical />
      <div class="personalInfoBookingPage__content">
        <div class="personalInfoBookingPage__row">
          <span>{{ $t("Phone") }}</span>
          <a
            class="personalInfoBookingPage__link"
            :href="`tel:${booking.reservationInfo.phone}`"
          >
            {{ booking.reservationInfo.phone }}
          </a>
        </div>
        <div class="personalInfoBookingPage__row">
          <span>{{ $t("Email") }}</span>
          <a
            class="personalInfoBookingPage__link"
            :href="`mailto:${booking.reservationInfo.email}`"
          >
            {{ booking.reservationInfo.email }}
          </a>
        </div>
        <div
          v-for="(field, index) in booking.reservationInfo.customFields"
          :key="index"
          class="personalInfoBookingPage__row"
        >
          <span>{{ field.label }}</span>
          <span>{{ getFieldValue(field) || "-" }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";

export default {
  name: "PersonalInfoBookingPage",
  components: { BackTitle },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      booking: (state) => state.bookings.editedItem,
    }),
    name() {
      return `${this.booking.reservationInfo.firstName} ${this.booking.reservationInfo.lastName}`;
    },
  },
  async created() {
    if (!this.booking) {
      try {
        this.isLoading = true;
        await this.fetchBookingById(this.$route.params.id);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchBookingById: "bookings/fetchBookingById",
    }),
    goBack() {
      this.$router.push({
        name: "BookingDetail",
      });
    },
    getFieldValue(field) {
      if (Array.isArray(field.value)) {
        return field.value.join(", ");
      }
      if (typeof field.value === "boolean") {
        return field.value ? "Yes" : "No";
      }
      return field.value;
    },
  },
};
</script>
<style lang="scss">
.personalInfoBookingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  height: 100%;

  &__content {
    box-shadow: $box-shadow-small;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    padding: 24px 16px;
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $secondary-500;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    :last-child {
      font-weight: 500;
    }
  }

  &__link {
    text-decoration: underline;
    color: $primary;
  }
}
</style>
